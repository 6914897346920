import React, { useEffect, useRef, useState, useMemo } from 'react';
import WaveSurfer from 'wavesurfer.js';

import ArtistPopup from './artistPopup'; // Import your ArtistPopup component


import GrasslandTye_ogg from '../assets/music/Grasslands-Tyrelle.ogg';
import GrasslandTye_mp3 from '../assets/music/Grasslands-Tyrelle.mp3';
import BattleTye_ogg from '../assets/music/BattleMusic-Tyrelle.ogg';
import BattleTye_mp3 from '../assets/music/BattleMusic-Tyrelle.mp3';
import HubTye_ogg from '../assets/music/theHub-Tyrelle.ogg';
import HubTye_mp3 from '../assets/music/theHub-Tyrelle.mp3';
import CavernCombat_ogg from '../assets/music/CavernsCombat-McCoy.ogg';
import CavernCombat_mp3 from '../assets/music/CavernsCombat-McCoy.mp3';
import CavernAmbience_ogg from '../assets/music/CavernsAmbienceMusic-McCoy.ogg';
import CavernAmbience_mp3 from '../assets/music/CavernsAmbienceMusic-McCoy.mp3';

import './music.css'; // Assuming your CSS file is named Navbar.css

const tyAlbum = require('assets/images/music_images/shaypeAlbumArt_reduced.webp');

const mccoyArt_webp = require('assets/images/team_art/mccoy_profile.webp');
const mccoyArt_jpg = require('assets/images/team_art/mccoy_profile.jpg');
const mccoyArt_thumb = require('assets/images/team_art/t_mccoy_profile.jpg');

const tyArt_webp = require('assets/images/team_art/ty_profile.webp');
const tyArt_jpg = require('assets/images/team_art/ty_profile.jpg');
const tyArt_thumb = require('assets/images/team_art/t_ty_profile.jpg');

// Define your songs here
const songs = [
  {
    oggSrc: CavernAmbience_ogg,// Default to ogg
    mp3Src: CavernAmbience_mp3,// Fallback to mp3 last resort
    title: 'Shifting Caverns Main',
    author: 'Swayen',
    art: tyAlbum,
    artistImg: mccoyArt_thumb,
  },
  {
    oggSrc: CavernCombat_ogg,// Default to ogg
    mp3Src: CavernCombat_mp3,// Fallback to mp3 last resort
    title: 'Cavern Combat',
    author: 'Swayen',
    art: tyAlbum,
    artistImg: mccoyArt_thumb,
  },
  {
    oggSrc: GrasslandTye_ogg,// Default to ogg
    mp3Src: GrasslandTye_mp3,// Fallback to mp3 last resort
    title: 'Grasslands',
    author: 'Tyrelle Massey',
    art: tyAlbum,
    artistImg: tyArt_thumb,
  },
  {
    oggSrc: BattleTye_ogg,// Default to ogg
    mp3Src: BattleTye_mp3,// Fallback to mp3 last resort
    title: 'Battle Music',
    author: 'Tyrelle Massey',
    art: tyAlbum,
    artistImg: tyArt_thumb,
  },
  {
    oggSrc: HubTye_ogg,// Default to ogg
    mp3Src: HubTye_mp3,// Fallback to mp3 last resorttitle: 'Hub Music',
    author: 'Tyrelle Massey',
    title: 'The Hub',
    art: tyAlbum,
    artistImg: tyArt_thumb,
  },
];

const artists = {
  tyrelle_massey: {
    name: 'Tyrelle Massey',
    title: 'Sound Designer',
    image: { webp: tyArt_webp, jpg: tyArt_jpg, thumb: tyArt_thumb},
    contacts: [
      { icon: "fa-solid fa-envelope", source: "tyrelle@tyrellesound.com", link: "mailto:tyrelle@tyrellesound.com" },
      { icon: 'fa-solid fa-globe', source: 'TyrelleSound.com', link: "https://www.tyrellesound.com/" },
      { icon: 'fa-brands fa-instagram', source: '@tyrellemassey', link: "https://www.instagram.com/tyrellemassey/"},
    ],
    info: "",
  },
  swayen: {
    name: "Swayen",
    title: "Composer",
    image: { webp: mccoyArt_webp, jpg: mccoyArt_jpg, thumb: mccoyArt_thumb},
    contacts: [
      { icon: 'fa-brands fa-instagram', source: '@swayenmusic', link: "https://www.instagram.com/swayenmusic/"},
      { icon: 'fa-brands fa-soundcloud', source: 'Swayen', link: "https://soundcloud.com/swayen/"},
      { icon: 'fa-brands fa-x-twitter', source: '@swayenmusic', link: "https://x.com/swayenmusic"},
    ],
    info: "",
  },
};

type ArtistKeys = keyof typeof artists; // This will infer 'tyrelle' | 'shaype'


interface MusicPlayerProps {
  startIndex?: number; // Accept an optional startIndex prop
}

const MusicPlayer: React.FC<MusicPlayerProps> = ({ startIndex }) => {
  const wavesurferRefs = useRef<(WaveSurfer | null)[]>([]);
  const [isPlaying, setIsPlaying] = useState<boolean[]>(Array(songs.length).fill(false));
  const [duration, setDuration] = useState<number[]>(Array(songs.length).fill(0));
  const [currentTime, setCurrentTime] = useState<number[]>(Array(songs.length).fill(0));
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentArtist, setCurrentArtist] = useState<any>(null); // Use `any` temporarily for flexibility
  const [volume, setVolume] = useState<number[]>(Array(songs.length).fill(0.5)); // Volume state



  //const displayedSongs = startIndex !== undefined ? [songs[startIndex]] : songs; // Determine songs to display based on startIndex
  const displayedSongs = useMemo(() => {
    return startIndex !== undefined ? [songs[startIndex]] : songs;
  }, [startIndex, songs]);

  

  useEffect(() => {
    // Intersection Observer for lazy loading songs
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const targetElement = entry.target as HTMLElement;
            const index = parseInt(targetElement.dataset.index || "0", 10); // Fetch the index from data attribute

            console.log("Initializing WaveSurfer for index:", index);

            // Clear existing waveform instance if any
            if (wavesurferRefs.current[index]) {
              wavesurferRefs.current[index]?.destroy(); // Safely destroy the previous instance
            }

            initializeWaveSurfer(index); // Initialize WaveSurfer when in view
            observer.unobserve(entry.target); // Stop observing once loaded
          }
        });
      },
      { threshold: 0.1 } // Trigger when 10% of the song element is visible
    );

    // Lazy loading function using IntersectionObserver
    const initializeWaveSurfer = (index: number) => {
      const containerId = `#waveform${index}`;
      const waveformContainer = document.querySelector(containerId);

      if (!waveformContainer) {
        console.error(`Waveform container not found for index ${index}`);
        return;
      }

      // Display "Loading..." before the waveform loads
      const loadingPlaceholder = document.createElement('div');
      loadingPlaceholder.innerText = "Loading...";
      loadingPlaceholder.classList.add('loading-waveform');
      waveformContainer.appendChild(loadingPlaceholder);

      // Initialize WaveSurfer for the unique song element
      const wavesurfer = WaveSurfer.create({
        container: containerId, // Unique ID for each waveform
        waveColor: '#656666',
        progressColor: '#EE772F',
        barWidth: 2,
        height: 64,
      });

      wavesurferRefs.current[index] = wavesurfer; // Store each instance

      wavesurfer.on('ready', () => {
        loadingPlaceholder.remove(); // Remove the loading placeholder
      });

      wavesurfer.setVolume(volume[index]); // Set initial volume

      wavesurfer.load(songs[index].oggSrc); // Load audio source

      wavesurfer.on('error', (err) => {
        console.error(`Error loading audio for track ${index}: ${err}`);
        loadingPlaceholder.innerText = "Audio format not supported";
      });

      // Multiple audio sources with fallback support
      const supportedAudioSources = [
        { src: displayedSongs[index].oggSrc, type: 'audio/ogg' }, // Ogg first
        { src: displayedSongs[index].mp3Src, type: 'audio/mp3' }, // Mp3 fallback
      ].filter(source => source.src); // Filter out invalid sources

      let audioLoaded = false;

      // Try to load sources
      supportedAudioSources.forEach((source) => {
        if (!audioLoaded) {
          try {
            wavesurfer.load(source.src); // Try loading the audio
            console.log(`Successfully loaded ${source.type} for track ${index}`);
            audioLoaded = true; // Mark as loaded
          } catch (err) {
            console.error(`Failed to load ${source.type} for track ${index}: ${err}`);
          }
        }
      });

      // Continuously update the time
      wavesurfer.on('audioprocess', (time) => {
        setCurrentTime((prev) => {
          const newCurrentTimes = [...prev];
          newCurrentTimes[index] = time;
          return newCurrentTimes;
        });
      });

      // Update duration when decoded
      wavesurfer.on('decode', (duration) => {
        setDuration((prev) => {
          const newDurations = [...prev];
          newDurations[index] = duration;
          return newDurations;
        });
      });

      if (waveformContainer) {
        const clickHandler = (event: Event) => {
          const mouseEvent = event as MouseEvent; // Cast to MouseEvent
          const offsetX = mouseEvent.offsetX;
          const waveformWidth = waveformContainer.clientWidth;
          const clickPositionRatio = offsetX / waveformWidth;
          const seekTime = clickPositionRatio * wavesurfer.getDuration();
          wavesurfer.seekTo(clickPositionRatio);
          setCurrentTime((prev) => {
            const newCurrentTimes = [...prev];
            newCurrentTimes[index] = seekTime;
            return newCurrentTimes;
          });
        };
        waveformContainer.addEventListener('click', clickHandler as EventListener);

        return () => {
          waveformContainer.removeEventListener('click', clickHandler as EventListener);
        };
      }
    };

    // Observe each song element for lazy loading
    displayedSongs.forEach((_, index) => {
      const songElement = document.querySelector(`#waveform${index}`);
      if (songElement) {
        songElement.setAttribute('data-index', index.toString()); // Assign unique index
        console.log(`Observing song element #waveform${index}`);
        observer.observe(songElement); // Start observing each song element
      } else {
        console.warn(`Waveform element #waveform${index} not found`);
      }
    });

    return () => {
      observer.disconnect(); // Cleanup the observer on unmount
      wavesurferRefs.current.forEach((ws) => {
        if (ws) {
          ws.destroy(); // Cleanup WaveSurfer instances on unmount
        }
      });
    };
  }, [displayedSongs]);
  
  
  
  const handleVolumeChange = (index: number, newVolume: number) => {
    setVolume((prev) => {
      const updatedVolumes = [...prev];
      updatedVolumes[index] = newVolume;
      return updatedVolumes;
    });
    wavesurferRefs.current[index]?.setVolume(newVolume);
  };
  
  
  
  const onPlayPause = (index: number) => {
    const wavesurfer = wavesurferRefs.current[index];
  
    if (wavesurfer) {
      // Check if another track is currently playing
      const currentlyPlayingIndex = isPlaying.findIndex((playing) => playing);
  
      if (currentlyPlayingIndex !== -1 && currentlyPlayingIndex !== index) {
        // Pause the currently playing track
        const currentlyPlayingWavesurfer = wavesurferRefs.current[currentlyPlayingIndex];
        if (currentlyPlayingWavesurfer) {
          currentlyPlayingWavesurfer.pause(); // Pause the current track
          setIsPlaying((prev) => {
            const newPlayingStates = [...prev];
            newPlayingStates[currentlyPlayingIndex] = false; // Update the state to reflect that this track is paused
            return newPlayingStates;
          });
        }
      }
  
      // Toggle play/pause for the selected track
      if (wavesurfer.isPlaying()) {
        wavesurfer.pause();
      } else {
        wavesurfer.play();
      }
  
      setIsPlaying((prev) => {
        const newPlayingStates = [...prev];
        newPlayingStates[index] = !newPlayingStates[index]; // Toggle the current track's playing state
        return newPlayingStates;
      });
    }
  };

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const secondsRemainder = Math.round(seconds) % 60;
    const paddedSeconds = `0${secondsRemainder}`.slice(-2);
    return `${minutes}:${paddedSeconds}`;
  };

  const handleArtistClick = (artistKey: ArtistKeys) => {
    const artistData = artists[artistKey];
    if (artistData) {
      // Your popup logic goes here, using artistData for content
      //console.log(artistData);
      console.log("Artist found: " + artistData.name);
      //setCurrentArtist(artists[artistKey]);
      setCurrentArtist(artistData);
      setIsPopupOpen(true);
    } else {
      console.error('Artist not found');
    }
    
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const formatArtistKey = (name: string) => name.toLowerCase().replace(/\s+/g, '_');

  return (
    <div className="song-list">
      {displayedSongs.map((song, index) => (
        <div className="song-element" key={index}>
          <img className="song-art" src={song.art} alt={`${song.title} Art`} />
          <div className="song-player">
            <div className="artist-element">
              <div>
                <h4 style={{ fontWeight: 100, margin: 0, cursor: "pointer"}} onClick={() => 
                handleArtistClick(formatArtistKey(song.author) as ArtistKeys)}
                >{song.author}</h4>
                <h2 style={{ fontWeight: 100, margin: 0 }}>{song.title}</h2>
              </div>
              <img
                className="song-artist"
                src={song.artistImg}
                alt={`${song.author} Profile`}
                onClick={() => 
                  handleArtistClick(formatArtistKey(song.author) as ArtistKeys)}
                style={{ cursor: "pointer" }}
              />
            </div>

            <div className="waveform" id={`waveform${index}`}></div>

            <div className="player-stats">
              <div className="player-row2-col1">
                <div className="player-btn">
                  <button onClick={() => onPlayPause(index)}>
                    {isPlaying[index] ? (
                      <i className="player-btn-ico fa-solid fa-pause"></i>
                    ) : (
                      <i className="player-btn-ico fa-solid fa-play"></i>
                    )}
                  </button>
                </div>
                <div className="player-timestamp">
                  <span>{formatTime(currentTime[index])}</span> /{" "}
                  <span>{formatTime(duration[index])}</span>
                </div>
              </div>
              <div className="player-row2-col2">
                <i
                  className={`player-vol-ico fa-solid ${
                    volume[index] === 0
                      ? "fa-volume-xmark"
                      : volume[index] <= 0.6
                      ? "fa-volume-low"
                      : "fa-volume-high"
                  }`}
                ></i>
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  value={volume[index]}
                  onChange={(e) =>
                    handleVolumeChange(index, parseFloat(e.target.value))
                  }
                />
              </div>
            </div>
          </div>
        </div>
      ))}

      {isPopupOpen && currentArtist && (
        <ArtistPopup artist={currentArtist} onClose={closePopup} />
      )}
    </div>
  );
};

export default MusicPlayer;
