// src/pages/Team.tsx
import React from 'react';

import Footer from 'components/footer'; // Import the Footer component

import NavBarTop from 'components/navBar_top'; // Import the Footer component
import NavBarMain from 'components/navBar_main'; // Import the Footer component
import TeamDetails from 'components/teamDetails'; // Adjust the import path as necessary


const mccoyArt_webp = require('assets/images/team_art/mccoy_profile.webp');
const mccoyArt_jpg = require('assets/images/team_art/mccoy_profile.jpg');
const mccoyArt_thumb = require('assets/images/team_art/t_mccoy_profile.jpg');

const tyArt_webp = require('assets/images/team_art/ty_profile.webp');
const tyArt_jpg = require('assets/images/team_art/ty_profile.jpg');
const tyArt_thumb = require('assets/images/team_art/t_ty_profile.jpg');

const squawshArt_webp = require('assets/images/team_art/squawsh_profile.webp');
const squawshArt_jpg = require('assets/images/team_art/squawsh_profile.jpg');
const squawshArt_thumb = require('assets/images/team_art/t_squawsh_profile.jpg');

const fyreArt_webp = require('assets/images/team_art/fyre_profile.webp');
const fyreArt_jpg = require('assets/images/team_art/fyre_profile.jpg');
const fyreArt_thumb = require('assets/images/team_art/t_fyre_profile.jpg');

const stratoArt_webp = require('assets/images/team_art/stephen_profile.webp');
const stratoArt_jpg = require('assets/images/team_art/stephen_profile.jpg');
const stratoArt_thumb = require('assets/images/team_art/t_stephen_profile.jpg');


const Team: React.FC = () => {
  
  const tyrelle = {
    name: 'Tyrelle Massey',
    title: 'Sound Designer',
    image: { webp: tyArt_webp, jpg: tyArt_jpg, thumb: tyArt_thumb},
    contacts: [
      { icon: "fa-solid fa-envelope", source: "tyrelle@tyrellesound.com", link: "mailto:tyrelle@tyrellesound.com" },
      { icon: 'fa-solid fa-globe', source: 'TyrelleSound.com', link: "https://www.tyrellesound.com/" },
      { icon: 'fa-brands fa-instagram', source: '@tyrellemassey', link: "https://www.instagram.com/tyrellemassey/"},
    ],
    info: "",
  };

  const mccoy = {
    name: "Swayen",
    title: "Composer",
    image: { webp: mccoyArt_webp, jpg: mccoyArt_jpg, thumb: mccoyArt_thumb},
    contacts: [
      { icon: 'fa-brands fa-instagram', source: '@swayenmusic', link: "https://www.instagram.com/swayenmusic/"},
      { icon: 'fa-brands fa-soundcloud', source: 'Swayen', link: "https://soundcloud.com/swayen/"},
      { icon: 'fa-brands fa-x-twitter', source: '@swayenmusic', link: "https://x.com/swayenmusic"},
    ],
    info: "",
  };

  const cody_j = {
    name: "FyreNight",
    title: "Lead Technical Artist",
    image: { webp: fyreArt_webp, jpg: fyreArt_jpg, thumb: fyreArt_thumb},
    contacts: [
      { icon: "fa-brands fa-instagram", source: "@sinkingshipstudiosla", link: "https://www.instagram.com/sinkingshipstudiosla" },
      { icon: "fa-brands fa-youtube", source: "@sinkingshipstudios-la", link: "https://www.youtube.com/" },
    ],
    info: "",
  };

  const chris_f = {
    name: "Christopher",
    title: "Game Director",
    image: { webp: squawshArt_webp, jpg: squawshArt_jpg, thumb: squawshArt_thumb},
    contacts: [
      { icon: "fa-brands fa-x-twitter", source: "@SinkingShipLA", link: "https://x.com/SinkingShipLA" },
    ],
    info: "I have no idea what I'm doing.",
  };

  const stephen_e = {
    name: "Stephen",
    title: "Technical Designer",
    image: { webp: stratoArt_webp, jpg: stratoArt_jpg, thumb: stratoArt_thumb},
    contacts: [
      { icon: "fa-brands fa-x-twitter", source: "@SinkingShipLA", link: "https://x.com/SinkingShipLA" },
      { icon: "fa-brands fa-patreon", source: "Sinking Ship Studios", link: "https://www.patreon.com/sinkingshipstudios" },
    ],
    info: "",
  };



  return (
    <div className="App">
        <NavBarTop theme="solid" /> {/* Add the Navbar component here */}
        <NavBarMain theme="solid" />{/* Add the Navbar component here */} 

        <section className="paragraph info">
            <h1>Meet the Team</h1>
            {/* <p>Add Tye, McCoy, Chris, Stephen, Cody</p> */}
            <hr className="hr-short" />
        </section>
        
        <section className="team-container">
          {/* Call the ArtistDetails component for each artist */}
          <TeamDetails artist={chris_f} />
          <TeamDetails artist={mccoy} />
          <TeamDetails artist={cody_j} />
          <TeamDetails artist={tyrelle} />
          <TeamDetails artist={stephen_e} />
        </section>
        <div style={{marginBottom: '15vh'}}></div>

        
        

        <Footer /> {/* Add the Footer component here */}
    </div>
  );
};

export default Team;
