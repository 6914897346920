// src/pages/Gallery.tsx
import React, { useEffect, useState } from 'react';

import Preloader from 'components/preloader';
import Footer from 'components/footer'; // Import the Footer component

import NavBarTop from 'components/navBar_top'; // Import the Footer component
import NavBarMain from 'components/navBar_main'; // Import the Footer component

import GalleryIMG from 'components/gallery';

const Gallery: React.FC = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Simulate page-specific loading delay of 2 seconds
    return () => clearTimeout(timer);
  }, []);
  
  return (
  <>
    {loading ? (
      <Preloader />
    ) : (



    <div className="App">
        <NavBarTop theme="solid" /> {/* Add the Navbar component here */}
        <NavBarMain theme="solid" />{/* Add the Navbar component here */} 

        <section className="paragraph info">
            <h1>Gallery</h1>
            {/* <p>Feel free to explore and download screenshots from our game</p> */}
            <hr className="hr-short" />
            <br></br>
        </section>
        
        { <GalleryIMG /> }
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <Footer /> {/* Add the Footer component here */}
    </div>



  )}
  </>
);
};

export default Gallery;
