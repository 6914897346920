// teamDetails.tsx
import React from 'react';

import './team.css';

interface Contact {
  icon: string;
  source: string;
  link: string;
}

interface Image {
  webp: string;
  jpg: string;
  thumb: string;
}

interface Artist {
  name: string;
  title: string;
  image: Image;
  contacts: Contact[];
  info: string,
}

const fallBackArt = require('assets/images/team_art/noProfilPic.jpg');

const TeamDetails: React.FC<{ artist: Artist }> = ({ artist }) => {
  return (
      <div className="artist-card">
        <h2 className="artist-name">{artist.name}</h2>
        <h3 className="artist-title">{artist.title}</h3>
        <picture>
          {/* WebP format (used if browser supports WebP) */}
          <source srcSet={artist.image.webp} type="image/webp" />
          {/* JPG format (fallback if WebP is not supported) */}
          <source srcSet={artist.image.jpg} type="image/jpeg" />
          {/* Fallback image element with onError fallback handling */}
          <img
            src={artist.image.jpg} 
            alt={artist.name}
            onError={(e) => (e.currentTarget.src = fallBackArt)}
          />
        </picture>
        <div className="contacts">
          {artist.contacts.map((contact, idx) => (
            <div key={idx} className="contact">
              <i className={`contact-icon ${contact.icon}`}></i>
              <span><a className='contact-source' href={contact.link} target="_blank" rel="noopener noreferrer">{contact.source}</a></span>
            </div>
          ))}
        </div>
        {artist.info && (
          <div className="info">
            {artist.contacts.length > 0  && (<hr style={{width: '75%'}}></hr>)}
            <p>{artist.info}</p>
          </div>
        )}
      </div>
  );
};

export default TeamDetails;
