import React, { useEffect, useState } from 'react';

//import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
//import FlexboxLayout from './components/flexboxLayout';
import Preloader from 'components/preloader';
//import './App.css';

import NavBarTop from 'components/navBar_top'; // Import the Footer component
import NavBarMain from 'components/navBar_main'; // Import the Footer component
import Footer from 'components/footer'; // Import the Footer component
import HeroCarousel from 'components/carousel';
import MusicPlayer from 'components/music';
import Gallery from 'components/gallery';

import ScrollButton from 'components/scrollButton'; // Import the reusable button

import ShaypeLogo from 'assets/images/home_images/SHAYPE_reduced.webp'; // Import the Footer component
const HeroVideo_MP4_high = require('assets/videos/HeroVid-Hi-Web.mp4');
const HeroVideo_WEBM_high = require('assets/videos/HeroVid-Hi-Web.webm');
const HeroVideo_MP4_low = require('assets/videos/HeroVid-Low-Web.mp4');
const HeroVideo_WEBM_low = require('assets/videos/HeroVid-Low-Web.webm');
const HeroVideoThumb = require('assets/images/home_images/HeroVideoThumb.jpg');



//FIX PRELOADER

const App: React.FC = () => {
  const [loading, setLoading] = useState(true);
  //const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Simulate page-specific loading delay of 2 seconds
    return () => clearTimeout(timer);
  }, []);


  return (
  <>
    {loading ? (
      <Preloader />
    ) : (


    <div className="App">

    <NavBarTop theme="transparent" /> {/* Add the Navbar component here */}
    <NavBarMain theme="transparent" />{/* Add the Navbar component here */} 
      

      <div className="hero-video-container">
      <div className="video-overlay"></div>
        <video autoPlay loop muted playsInline className="background-video" poster={HeroVideoThumb}>
          {/* Low quality videos */}
          <source src={HeroVideo_WEBM_low} type="video/webm" media="(max-width: 768px)" />
          <source src={HeroVideo_MP4_low} type="video/mp4" media="(max-width: 768px)" />

          {/* High quality videos */}
          <source src={HeroVideo_WEBM_high} type="video/webm" />
          <source src={HeroVideo_MP4_high} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <section className="content">
        <div className="overlay center">
          <img src={ShaypeLogo} alt="Shaype Logo" className="hero-logo" />
        </div>
        <ScrollButton label="Explore" targetId="summary" />
      </section>
      
      <section className="paragraph info">
        <p id="summary" style={{fontSize: '16px', lineHeight: '150%'}}>
          <b>Shaype</b> is a singleplayer fantasy action role-playing game with an emphasis on
          player agency, character progression, engaging combat, interactive dialogue, and player-driven narratives.
        </p>
      </section>
      <br />

      

      <section className="list info">
      <h2 style={{ fontSize: '30px' }}>Main Features:</h2>
      <hr className="hr-short" />
      
      <h3>Player Agency and Reactivity</h3>
      <ul className='homepage-list'>
        <li>Shaype offers multiple resolutions to the problems of diverse characters in a reactive world, rewarding imagination and creativity through versatile player-driven outcomes.</li>
      </ul>

      <h3>Combat</h3>
      <ul className='homepage-list'>
        <li><b>Shaype's</b> combat was designed to be easy to learn but hard to master, testing the ability of the player to problem solve and find openings to strike.</li>
        <br />
        <li><b>Shaype</b> features angled melee combat, ranged combat, stealth, abilities, and an in-depth spell crafting system for magic.</li>
      </ul>

      <h3>Character Progression</h3>
      <ul className='homepage-list'>
        <li><b>Shaype</b> presents an open-ended progression system which supports the mixing and matching of typical RPG playstyles. Build your character however you wish.</li>
      </ul>

      <h3>World</h3>
      <ul className='homepage-list'>
        <li><b>Shaype</b> is set in the inhospitable land of Paradise. Build reputation with the competing factions and settlements that tensely share this harsh and violent land.</li>
      </ul>
    </section>

    <section className="paragraph center">
      <a href="https://discord.gg/ZADcjxhw4q" className="btn discord_btn" target="_blank" rel="noopener noreferrer">Join Our Discord</a>
    </section>

    <section className="video-section">
      <div className="video-wrapper">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/hr_Eg56MBpA"
          title="YouTube video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          loading="lazy"
        ></iframe>
      </div>
    </section>

    {/* <div id="discordInvite" style={{ display: 'block' }}>
      <h2 style={{ textAlign: 'center', marginTop: 50}}>Join Our Discord!</h2>
      <div id="discordContent">
        {isMobile ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <a href="https://discord.gg/UqcTEd9" target="_blank" rel="noopener noreferrer" style={{ fontSize: '24px', color: '#7289DA' }}>
              <i className="fab fa-discord"></i>
            </a>
          </div>
        ) : (
          <div style={{ width: '50%', marginLeft: 'auto', marginRight: 'auto' }}>
            <iframe
              src="https://discordapp.com/widget?id=589181117731569901&theme=dark"
              style={{ width: '100%', minWidth: '250px', textAlign: 'center', marginLeft: '0', marginRight: '0' }}
              height="500px"
              
              frameBorder="0"
              title="Join the Sinking Ship Studios Discord"
            ></iframe>
          </div>
        )}
      </div>
    </div>
      */}

    <section className="music info">
        <h1 style={{ marginTop: 50}}>Listen</h1>
        <p>
            {/* Most recent track from our game */}
        </p>
        <hr className="hr-short" />
        <br></br>

        <section className="paragraph info"> <MusicPlayer startIndex={1}/> </section>

        <section className="paragraph center">
            <a href="./Music" className="btn cta_btn">More Music</a>
        </section>
    </section>
    {/* <FlexboxLayout /> */} {/* used to display float grid elements */}
    {/*  <HeroCarousel /> */}
    {/*  <Gallery /> */}

    <Footer /> {/* Add the Footer component here */}
    </div>

  )}
  </>
);
};

export default App;

 